<template>
    <ErrorModal v-if="showErrorModal" @closed="showErrorModal = false" >
        <i class="fas fa-exclamation modal-icon"></i>
    </ErrorModal>
    <main class="obscure-wrapper">
        <section class="container main">
            <div class="row g-0">
                <div class="row">
                    <div class="col">
                        <div class="programs-header">
                            <h2 class="p-title">Datos de pago</h2>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <shopping-cart viewType="full" />
                </div>
            </div>
            <div class="row g-0">
                <div class="col-md-7">
                    
                    <h2>Datos de tarjeta</h2>
                    <Textfield @change="validateField('card.name')" :check="!errors.card.name" :error="errors.card.name" v-model="card.name" placeholder="NOMBRE DEL TARJETAHABIENTE" maxlength="250"/>
                    <Textfield @change="validateField('card.number')" :check="!errors.card.number" :error="errors.card.number" v-model="card.number" type="number" placeholder="NÚMERO DE TARJETA" maxlength="16"/>
                    <div class="row">
                        <div class="col error">
                            {{ errors.cardShared }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <Textfield @change="validateField('card.expiryMonth')" :error="errors.card.expiryMonth" v-model="card.expiryMonth" type="number" placeholder="MES (MM)" maxlength="2"/>
                        </div>
                        <div class="col">
                            <Textfield @change="validateField('card.expiryYear')"  :error="errors.card.expiryYear" v-model="card.expiryYear" type="number" placeholder="AÑO (YY)" maxlength="2"/>
                        </div>
                        <div class="col-5">
                            <Textfield @change="validateField('card.cvv')" :check="!!card.expiryYear && !!card.expiryMonth" :error="errors.card.cvv" v-model="card.cvv" type="number" placeholder="CCV" maxlength="3"/>
                        </div>
                    </div>

                    <h2><checkbox class="factData" :classes="'big-checkbox'" v-model="useInvoice" >Deseo facturar</checkbox> </h2>
                    <div v-if="useInvoice">
                        <Textfield @change="validateField('invoiceInfo.name')" :check="true" :error="errors.invoiceInfo.name" v-model="invoiceInfo.name" placeholder="RAZÓN SOCIAL" maxlength="500"/>
                        <Textfield @change="validateField('invoiceInfo.rfc')" :check="true" :error="errors.invoiceInfo.rfc" v-model="invoiceInfo.rfc" placeholder="RFC" maxlength="13"/>
                        <Textfield @change="validateField('invoiceInfo.phone')" :check="true" :error="errors.invoiceInfo.phone" v-model="invoiceInfo.phone" type="number" placeholder="TELÉFONO" maxlength="10"/>
                        <Textfield @change="validateField('invoiceInfo.street')" :check="true" :error="errors.invoiceInfo.street" v-model="invoiceInfo.street" placeholder="CALLE" maxlength="250"/>
                        <div class="row">
                            <div class="col-6">
                                <div v-if="errors.invoiceInfo.extNumber"> 	&nbsp;</div>
                                <Textfield :error="errors.invoiceInfo.intNumber" v-model="invoiceInfo.intNumber" placeholder="NÚMERO INT." maxlength="15" />
                            </div>
                            <div class="col-6">
                                <Textfield @change="validateField('invoiceInfo.extNumber')" :check="true" :error="errors.invoiceInfo.extNumber" v-model="invoiceInfo.extNumber" placeholder="NÚMERO EXT" maxlength="15"/>
                            </div>
                        </div>
                        <Textfield :check="true" :error="errors.invoiceInfo.suburb" v-model="invoiceInfo.suburb" placeholder="COLONIA" maxlength="45" />
                        <Multidown :check="true" :searchable="true" v-model="invoiceInfo.idState" :options="statesOpts" defaultText="ESTADO" modo="single" @select="loadCities" />
                        <Multidown @select="validateField('invoiceInfo.idCityInt')" :check="true" :searchable="true" :error="errors.invoiceInfo.idCityInt" v-model="invoiceInfo.idCityInt" :disabled="citiesLoading" :options="citiesOpts" defaultText="CIUDAD" modo="single" />
                        <Textfield @change="validateField('invoiceInfo.zipCode')" :check="true" :error="errors.invoiceInfo.zipCode" v-model="invoiceInfo.zipCode" type="number" placeholder="CÓDIGO POSTAL" maxlength="5"/>
                    </div>
                </div>
            </div>
            <div class="footer-controls">
                <Button black @click="back">ATRÁS</Button>    
                <Button @click="checkout" :disabled="!canBuy">SIGUIENTE</Button>
            </div>
        </section>
    </main>
</template>
<script>
import ErrorModal from '@/components/modals/Error'

export default {
    components: { ErrorModal },
    data(){
        return {
            useInvoice: false,
            cities: [],
            citiesLoading: false,
            showErrorModal: false,
            errors: {card: {}, invoiceInfo: {}},
            invoiceInfo: {
                idCityInt: null,
                name: "",
                rfc: "",
                street: "",
                suburb: "",
                intNumber: null,
                extNumber: 100,
                zipCode: "",
                phone: "",
                idState: ""
            },
            card: {
                name: "",
                number: "",
                expiryMonth: "",
                expiryYear: "",
                cvv: ""
            }
        }
    },
    async mounted() {
        this.$loading = true;
        await this.$store.dispatch('getStates');
        try {
            const invoiceInfoReq = await this.$kuAPI.getInvoiceInfo();
            this.invoiceInfo = invoiceInfoReq.data
            this.loadCities();

        } catch (error) {
            this.invoiceInfo = {};
        }
        this.$loading = false;

    },
    methods: {
        back(){
            this.$router.push('/programs');
        },
        async checkout(){
            if (this.$loading || !this.validateForm()) return;
            this.$loading = true;
            try {
                const paymentReq = await this.$kuAPI.checkoutOrder({
                    programs: this.$store.state.shoppingCart.map(i => i.idProgramInt),
                    invoiceInfo: this.useInvoice ?  this.invoiceInfo : null,
                    card: this.card
                });
                const payData = paymentReq.data;

                await this.$store.dispatch('checkout', this.$store.state.shoppingCart)

                this.$router.push('/ticket?id='+payData.idPayment);
            } catch (error) {
                this.showErrorModal = true; 
            }
            this.$loading = false;
        },
        async loadCities() {
            if (this.citiesLoading) return;
            this.citiesLoading = true;
            try {
              const citiesReq = await this.$kuAPI.getCitiesByState(this.invoiceInfo.idState);
              const lastCity = this.invoiceInfo.idCityInt;
              this.invoiceInfo.idCityInt = null;
              this.cities = citiesReq.data;
              if (this.cities.some(c => c.idCityInt == lastCity))
                this.invoiceInfo.idCityInt = lastCity;
            } catch (error) {
              this.cities = [];  
            }
            this.citiesLoading = false;
        },
        validatefieldsInObj(dataObjName, optionals) {
            let someError = false;
            const dataObj = this[dataObjName];

            for (const field in dataObj) {
                if (Object.hasOwnProperty.call(dataObj, field)) {
                    const isOptional = (Array.isArray(optionals) && optionals.indexOf(field) != -1);
                    if (this.validateField(dataObjName+'.'+field, isOptional)){
                        someError = true;
                    }
                }
            }

            return someError;
        },
        validateField(name, optional){
            const nameParts = name.split('.');
            const objName = nameParts[0];
            const key = nameParts[1];
            this.errors[objName][key] = false;
            this.errors.cardShared = '';

            const element = this[objName][key];
            if (!optional && (!element || !element.toString().trim())) {
                const sharedError = ['cvv', 'expiryYear', 'expiryMonth'];
                if (sharedError.indexOf(key) != -1){
                    this.errors.cardShared = 'Este campo es requerido';
                    this.errors[objName][key] = true;
                }else {
                    this.errors[objName][key] = 'Este campo es requerido';
                }
            } else {
                const year = new Date().getFullYear().toString().substr(-2) * 1;
                const valueNum = element*1;

                switch (key) {
                    case 'rfc': 
                        if (!element.match(/[A-Z0-9]{13}/))
                            this.errors[objName][key] = "El RFC debe contener 13 caracteres alfanuméricos";
                        break;
                    case 'zipCode': 
                        if (!element.match(/\d{5}/))
                            this.errors[objName][key] = "El código postal debe contener 5 dígitos";
                        break;
                    case 'phone': 
                        if (!element.match(/\d{5}/))
                            this.errors[objName][key] = "El número telefónico debe contener 10 dígitos";
                        break;
                    case 'number':
                        if (!element.match(/\d{16}/))
                            this.errors[objName][key] = "Por favor introduce los 16 dígitos de tu tarjeta";
                        break;
                    case 'expiryYear':
                        if (!element.match(/\d{2}/)){
                            this.errors.cardShared = "El año únicamente debe contener los últimos 2 dígitos";
                            this.errors[objName][key] = true;
                        }else if (valueNum < year){
                            this.errors.cardShared = "El año de vencimiento debe ser posterior o igual al actual";
                            this.errors[objName][key] = true;
                        }
                        break;
                    case 'expiryMonth':
                        if (!element.match(/\d{2}/)){
                            this.errors.cardShared = "El mes debe contener 2 dígitos";
                            this.errors[objName][key] = true;
                        }
                        if (valueNum > 12) {
                            this[objName][key] = 12;
                        } else if (valueNum < 1){
                            this[objName][key] = '01';
                        }
                        break;
                     case 'cvv':
                        if (!element.match(/\d{3}/)){
                            this.errors.cardShared = "El CVV debe contener 3 dígitos";
                            this.errors[objName][key] = true;
                        }
                        break;
                    default:
                        break;
                }
            }

            return this.errors[objName][key];

        },
        validateForm() {
            this.errors = {card: {}, invoiceInfo: {}}
            let isValidCard = !this.validatefieldsInObj('card');
            let isValidInvoice = true;
            if (this.useInvoice)
                isValidInvoice = !this.validatefieldsInObj('invoiceInfo', ['suburb','intNumber']);

            return isValidCard && isValidInvoice;
        }
    },
    computed: {
        statesOpts(){
            return this.states.map((s) => {
                return {value: s.idState, label: s.name};
            });
        },
        citiesOpts(){
            return this.cities.map((c) => {
                return {value: c.idCityInt, label: c.name};
            });
        },
        states(){
            return this.$store.state.states || [];
        },
        canBuy(){
            return this.$store.state.shoppingCart.length > 0;
        }
    }
}
</script>
<style lang="scss" scoped>
h2:not(.p-title) {
    margin: 80px 0 40px;
}



</style>