<template>
<div class="modal-wrapper">
    <div class="modal ku-error-modal" :class="cls" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-header">
                    <slot></slot>
                    <h3>{{title}}</h3>
                </div>
                <div class="modal-body">
                    <p>{{message}}</p>
                    <Button @click="closed">ACEPTAR</Button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            cls: ''
        }
    },
    props: {
        title:{
            type: String,
            default: 'Ups! Algo salió mal'
        },
        message:{
            type: String,
            default: 'Ocurrió un problema al realizar la transacción, por favor intente de nuevo.'
        }
    },
    methods: {
        closed(){
            this.$emit('closed');
        }
    },
    mounted() {
        document.body.className = 'modal-body-wrapper';
    },
     unmounted(){
         document.body.className = '';
     }
}
</script>
<style lang="scss">
    .modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        z-index: 99999;
    }
</style>
<style lang="scss" scoped>
.ku-error-modal {
    display: block;
    .modal-body, .modal-header {
        padding: 30px;
    }
    .modal-header{
        border-bottom: none;
        justify-content: center;
        padding-top: 70px;
        flex-direction: column;
        padding-bottom: 0;
        
    }
    .modal-dialog {
        max-width: 350px;
        .modal-content{
            border-radius: 16px;
            text-align: center;
            background: #2b2b2b;
            .fas {
                cursor:pointer;
            }
        }
    }
    &.closed {
        display: none !important;
    }
}

</style>